<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Edit User">
    <div class="mb-6 vx-row" style="width: 50%">
      <vs-button
        class="mt-2 ml-4"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="w-full vx-col mb-base">
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*WorkGroup</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedWorkGroup"
              :options="optionWorkGroup"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @select="SelectWorkGroup()"
              label="name"
              v-validate="'required'"
            />
            <span
              class="text-sm text-danger"
              v-show="errors.has('WorkGroup')"
              >{{ errors.first("WorkGroup") }}</span
            >
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Role</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedRoles"
              :options="optionRoles"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              name="Role"
              label="display_name"
            />
            <span class="text-sm text-danger" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Status</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-switch
              v-model="status"
              color="success"
              :disabled="false"
              :value="true"
              :true-value="true"
              :false-value="false"
            >
            </vs-switch>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Work Id Number</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="workIDNumber"
              class="w-full"
              name="workIDNumber"
              disabled
            />
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Id Number</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input
              v-model="idNumber"
              class="w-full"
              name="idNumber"
              type="number"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('idNumber')">{{
              errors.first("idNumber")
            }}</span>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Name</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input
              v-model="name"
              class="w-full"
              name="name"
              v-validate="'required'"
            />
            <span class="text-sm text-danger" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
          </div>
        </div>

        <div class="mb-6 vx-row" v-if="isDisabled == false">
          <div class="w-full vx-col sm:w-1/4">
            <span>Lincense Type</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedLincenseType"
              :options="optionLincenseType"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
          </div>
        </div>
        <div class="mb-6 vx-row" v-if="isDisabled == false">
          <div class="w-full vx-col sm:w-1/4">
            <span>License Number</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input v-model="licenseNumber" class="w-full" type="number" />
          </div>
        </div>
        <div class="mb-6 vx-row" v-if="isDisabled == false">
          <div class="w-full vx-col sm:w-1/4">
            <span>License Validity</span>
          </div>
          <div class="vx-col w-1/4">
            <vs-input v-model="licenseValidity" class="w-full" type="date" />
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDisabled == false">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Type</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedType"
              :options="optionType"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              name="source"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('source')"
              >Type is required</span
            >
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDisabled == true">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :multiple="true"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              name="warehouse"
              @search-change="searchWarehouse($event)"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('warehouse')"
              >Warehouse is required</span
            >
          </div>
        </div>
        <div class="vx-row mb-6" v-if="isDisabled == false">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :multiple="true"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              name="warehouse"
              @search-change="searchWarehouse($event)"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('warehouse')"
              >Warehouse is required</span
            >
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Address</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input
              v-model="address"
              class="w-full"
              name="address"
              v-validate="'required'"
            />
            <span class="text-sm text-danger" v-show="errors.has('address')">{{
              errors.first("address")
            }}</span>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Country</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedCountry"
              :options="optionCountry"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
            />
            <span class="text-sm text-danger" v-show="errors.has('Country')">{{
              errors.first("Country")
            }}</span>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Province</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedProvince"
              :options="optionProvince"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              label="name"
              @select="getCity()"
            />
            <span class="text-sm text-danger" v-show="errors.has('Province')">{{
              errors.first("Province")
            }}</span>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*City</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedCity"
              :options="optionCity"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @select="getDistrict()"
              label="name"
            />
            <span class="text-sm text-danger" v-show="errors.has('City')">{{
              errors.first("City")
            }}</span>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>District</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedDistrict"
              :options="optionDistrict"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              @select="getSubDistrict()"
              track-by="id"
              label="name"
            />
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>Sub District</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <multiselect
              class="selectExample"
              v-model="selectedSubDistrict"
              :options="optionSubDistrict"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @select="getPostalCode()"
              label="name"
            />
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>Postal Code</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input v-model="postal_code" class="w-full" disabled />
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>Mobile</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input v-model="mobile" class="w-full" type="number" />
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>*Email</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input
              v-model="email"
              class="w-full"
              type="email"
              name="email"
              v-validate="'required'"
            />
            <span class="text-sm text-danger" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <span>Password</span>
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <vs-input v-model="password" class="w-full" type="password" />
          </div>
        </div>
        <div class="mb-6 vx-row">
          <div class="w-full vx-col sm:w-1/4">
            <label
              >Avatar<small style="color: red"
                >(only: jpg, jpeg, png)</small
              ></label
            >
          </div>
          <div class="w-full vx-col sm:w-3/4">
            <div class="vx-col">
              <input
                id="fileAvatar"
                name="avatar"
                class="inputx"
                type="file"
                ref="avatar"
                accept=".jpg, .jpeg, .png"
              />
              <vs-button
                v-if="avatar != ''"
                type="line"
                icon-pack="feather"
                icon="icon-download"
                @click="downloadAvatar"
              />
            </div>
          </div>
        </div>
        <div v-if="isDisabled == false">
          <div class="mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/4">
              <label
                >Attachment
                <small style="color: red">(only: jpg, jpeg, png)</small></label
              >
            </div>
            <div class="w-full vx-col sm:w-3/4">
              <div class="vx-col">
                <!-- only accept "jpg", "jpeg", "png" -->
                <input
                  id="fileInput"
                  name="attachment"
                  class="w-full inputx"
                  type="file"
                  ref="file"
                  multiple="multiple"
                  accept=".jpg, .jpeg, .png"
                />
                <span
                  class="text-sm text-danger"
                  v-show="errors.has('attachment')"
                  >{{ errors.first("attachment") }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="mt-6 mb-3 vx-row w-4/4"
            style="width: 100%; margin-left: 0%"
          >
            <div class="w-full vx-col sm:w-1/4">
              <span></span>
            </div>
            <div class="w-full vx-col sm:w-3/4">
              <div class="w-full vx-col sm:w-4/5">
                <vs-button class="mb-2 mr-3" @click="handleAttachment"
                  >Add Attachment</vs-button
                >
              </div>
            </div>
          </div>
          <!-- display: block -->
          <vs-divider style="width: 60%; margin-left: 30%">
            List Attachment
          </vs-divider>
          <div class="mt-6 mb-3 vx-row" style="width: 60%; margin-left: 30%">
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.id"
                  v-for="(tr, i) in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.NameFile }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr)"
                      />
                    </vx-tooltip>
                  </td>

                  <td class="td vs-table--td">
                    <template>
                      <vx-tooltip text="Delete">
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click.stop="handleDeleteAttachment(i, tr.id)"
                        />
                      </vx-tooltip>
                    </template>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="vx-row">
          <div class="w-full vx-col sm:w-6/12">
            <vs-button class="mb-2 mr-3" v-on:click="SubmitForm()"
              >Save</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  async mounted() {
    await this.getData(this.$route.params.id);

    // await this.getRoles();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    // selectedCountry(val) {
    //   if (val) {
    //     this.getProvince();
    //   }
    // },
    // selectedProvince(val) {
    //   if (val) {
    //     this.getCity();
    //   }
    // },
    // selectedCity(val) {
    //   if (val) {
    //     this.getDistrict();
    //   }
    // },
    // selectedDistrict(val) {
    //   if (val) {
    //     this.getSubDistrict();
    //   }
    // },
    // selectedSubDistrict(val) {
    //   if (val) {
    //     this.postal_code = val.postal_code;
    //   }
    // },
    selectedRoles(val) {
      if (val) {
        if (val.name == "Driver") {
          this.isDisabled = false;
        } else {
          this.licenseNumber = "";
          this.licenseValidity = "";
          this.selectedLincenseType = null;
          this.attachment = "";
          this.selectedType = null;
          this.isDisabled = true;
        }
      }
    },

    // selectedWorkGroup(val) {
    //   if (!val) {
    //     this.selectedRoles = null;
    //   }
    // },
  },
  data: () => ({
    start: 1,
    end: 0,
    length: -1,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    code: "",
    notes: "",
    avatar: "",
    attachment: "",
    fileAttachment: [],
    IDAttachment: [],
    tommorow: moment().add(1, "days").format("YYYY-MM-DD"),
    user_id: null,
    isDisabled: true,
    optionWarehouse: [],
    optionCountry: [],
    optionWorkGroup: [],
    optionRoles: [],
    optionProvince: [],
    optionCity: [],
    optionDistrict: [],
    optionSubDistrict: [],
    optionType: [
      {
        id: 1,
        name: "Internal",
      },
      {
        id: 2,
        name: "External",
      },
      {
        id: 3,
        name: "OneTime",
      },
    ],
    optionLincenseType: [
      {
        id: 1,
        name: "A",
      },
      {
        id: 2,
        name: "B1",
      },
      {
        id: 3,
        name: "B2",
      },
      {
        id: 4,
        name: "C",
      },
      {
        id: 5,
        name: "B1 Umum",
      },
      {
        id: 6,
        name: "B2 Umum",
      },

      {
        id: 7,
        name: "Internasional",
      },
    ],

    selectedWorkGroup: null,
    selectedRoles: null,
    selectedCountry: null,
    selectedProvince: null,
    selectedCity: null,
    selectedType: null,
    selectedDistrict: null,
    selectedSubDistrict: null,
    selectedLincenseType: null,
    selectedWarehouse: [],
    personal_id: 0,
    workIDNumber: "",
    idNumber: null,
    name: "",
    address: "",
    postal_code: "",
    status: true,
    licenseNumber: null,
    licenseValidity: "",
    mobile: null,
    email: "",
    password: "",
    isTransactions: false,
    avatarIsOK: false,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "setting.user",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    SubmitForm() {
      if (this.isTransactions) {
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: "User Have Transactions",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (!this.selectedWorkGroup) {
          this.errors.add({
            field: "WorkGroup",
            msg: "The Workgroup field is required",
          });
          result = false;
        }
        if (!this.selectedRoles) {
          this.errors.add({
            field: "Role",
            msg: "The Role field is required",
          });
          result = false;
        }
        if (!this.selectedCountry) {
          this.errors.add({
            field: "Country",
            msg: "The Country field is required",
          });
          result = false;
        }
        if (!this.selectedProvince) {
          this.errors.add({
            field: "Province",
            msg: "The Province field is required",
          });
          result = false;
        }
        if (!this.selectedCity) {
          this.errors.add({
            field: "City",
            msg: "The City field is required",
          });
          result = false;
        }

        if (result) {
          this.$vs.loading();

          if (this.status == true) {
            this.status = "1";
          } else {
            this.status = "0";
          }
          this.putData();
        }
      });
    },
    putData() {
      // let warehouse_id = [];
      // this.selectedWarehouse.map((el) => {
      //   warehouse_id.push(el.id);
      // });
      // this.idNumber = this.idNumber.toString();
      // this.licenseNumber = this.licenseNumber.toString();
      // this.mobile = this.mobile.toString();
      let WHIDs = [];
      this.selectedWarehouse.map((el) => {
        WHIDs.push(el.id);
      });
      this.$http
        .put("api/wms/v1/setting/users/update", {
          id: this.personal_id,
          user_id: this.user_id,
          work_group_id: this.selectedWorkGroup.id,
          role_id: this.selectedRoles.id,
          role: this.selectedRoles.name,
          work_id_number: this.workIDNumber ? this.workIDNumber : null,
          id_number: this.idNumber,
          name: this.name,
          address: this.address,
          country: this.selectedCountry.name,
          province: this.selectedProvince.name,
          city: this.selectedCity.name,
          district: this.selectedDistrict ? this.selectedDistrict.name : null,
          sub_district: this.selectedSubDistrict
            ? this.selectedSubDistrict.name
            : null,
          postal_code: this.postal_code,
          mobile: this.mobile,
          email: this.email,
          password: this.password,
          is_active: this.status,
          license_type:
            !this.isDisabled && this.selectedLincenseType
              ? this.selectedLincenseType.name
              : null,
          license_number: this.licenseNumber,
          license_validity: this.licenseValidity,
          // source: this.selectedType ? this.selectedType.name : null,
          // warehouse_id: warehouse_id,
          type: this.selectedType ? this.selectedType.name : null,
          warehouse_id: WHIDs,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "User has been Updated",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.user_id = resp.data;
            // this.handleSubmitAttachment();
            this.handleSubmitAvatar();
            this.handleBack();
          } else {
            this.attachment = [];
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    async getWorkGroup() {
      this.$http.get("api/wms/v1/master/work-groups").then((resp) => {
        if (resp.code == 200) {
          this.optionWorkGroup = resp.data.records;
          if (this.selectedWorkGroup != null) {
            console.log("selectedWorkGroup", this.selectedWorkGroup);
            this.selectedWorkGroup = this.optionWorkGroup.find(
              (el) => el.id == this.selectedWorkGroup.id
            );
            console.log("selectedWorkGroupV2", this.selectedWorkGroup);
            this.getRoles();
          }
        }
      });
    },
    SelectWorkGroup() {
      this.selectedRoles = null;

      this.getRoles();
    },
    async getRoles() {
      this.$http
        .get("api/wms/v1/master/roles", {
          params: {
            length: 999,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
            group: this.selectedWorkGroup.role_group,
            source: "WMS",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionRoles = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
                display_name: el.display_name,
              };
            });
            // if (this.selectedRoles != null) {
            //   this.selectedRoles = this.optionRoles.find(
            //     (el) =>
            //       el.id == this.selectedRoles.id &&
            //       el.name == this.selectedRoles.name
            //   );
            // }
          }
        });
    },
    async getCountry() {
      this.$http.get("api/wms/v1/master/country").then((resp) => {
        if (resp.code == 200) {
          this.optionCountry = resp.data.records.map((el) => {
            return {
              id: el.id,
              name: el.name,
            };
          });
          console.log("optionCountry", this.optionCountry);
          if (this.selectedCountry != null) {
            this.selectedCountry = this.optionCountry.find(
              (el) => el.name == this.selectedCountry.name
            );

            this.getProvince();
          }
        }
      });
    },
    getProvince() {
      this.$http
        .get("api/wms/v1/master/province", {
          params: {
            country_id: this.selectedCountry.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionProvince = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });

            if (this.selectedProvince != null) {
              this.selectedProvince = this.optionProvince.find(
                (el) => el.name == this.selectedProvince.name
              );
              this.getCity();
            }
          }
        });
    },
    getCity() {
      this.$http
        .get("api/wms/v1/master/city", {
          params: {
            province_id: this.selectedProvince.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCity = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });

            if (this.selectedCity != null) {
              this.selectedCity = this.optionCity.find(
                (el) => el.name == this.selectedCity.name
              );
              this.getDistrict();
            }
          }
        });
    },
    getDistrict() {
      this.$http
        .get("api/wms/v1/master/district", {
          params: {
            city_id: this.selectedCity.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionDistrict = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });

            if (this.selectedDistrict != null) {
              this.selectedDistrict = this.optionDistrict.find(
                (el) => el.name == this.selectedDistrict.name
              );
              this.getSubDistrict();
            }
          }
        });
    },
    getSubDistrict() {
      this.$http
        .get("api/wms/v1/master/sub-district", {
          params: {
            city_id: this.selectedCity ? this.selectedCity.id : null,
            district_id: this.selectedDistrict
              ? this.selectedDistrict.id
              : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSubDistrict = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
                postal_code: el.postal_code,
              };
            });
          }
        });
    },
    getPostalCode() {
      this.postal_code = this.selectedSubDistrict.postal_code;
    },
    async getWarehouse(val) {
      this.$vs.loading();
      this.$http
        .get("api/wms/v1/master/warehouses", {
          params: {
            length: this.length,
            page: this.page,
            search: val,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWarehouse = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });

            this.$vs.loading.close();
          }
          this.$vs.loading.close();
        });
    },
    searchWarehouse(val) {
      if (val) {
        if (val.length < 3) {
          return;
        }
      }
      this.getWarehouse(val);
    },

    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        let newName = "";
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: newName == "" ? this.file[i].name : newName,
            File: this.file[i],
          });
        }
        this.handleSubmitAttachment();
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    downloadAvatar() {
      var href = this.avatar;
      window.open(href, "_blank").focus();
    },
    handleShowAttachment(file) {
      console.log("33333333333", file);
      // let objectURL;
      // objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = file.PathFile;
      window.open(href, "_blank").focus();
    },
    handleShowAttachmentUrl(url) {
      // link.download = file.name; // this name is used when the user downloads the file
      var href = url;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index, id) {
      this.fileAttachment = this.fileAttachment.filter((item, i) => i != index);
      this.deleteFile(id);
      console.log(this.fileAttachment);
    },
    deleteFile(id) {
      this.$vs.loading();
      this.$http
        .post("api/wms/v1/setting/users/delete-attachment", {
          id: id,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleSubmitAttachment() {
      console.log("this.fileAttachment", this.fileAttachment);

      this.$vs.loading();
      if (this.fileAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.fileAttachment.length; index++) {
          if (this.fileAttachment[index].File) {
            formData.append("attachments[]", this.fileAttachment[index].File);
          }
        }
        formData.append("user_id", this.user_id);

        this.$http
          .post("api/wms/v1/setting/users/attachment", formData)
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Attachment has been added",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading.close();
      }
    },
    handleSubmitAvatar() {
      this.$vs.loading();
      if (this.$refs.avatar.files.length > 0) {
        const formData = new FormData();
        formData.append("avatar", this.$refs.avatar.files[0]);
        formData.append("user_id", this.user_id);

        this.$http
          .post("api/wms/v1/setting/users/avatar", formData)
          .then((resp) => {
            if (resp.code == 200) {
              this.avatarIsOK = true;
            } else {
              this.avatarIsOK = false;
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          });
      } else {
        this.avatarIsOK = true;
        this.$vs.loading.close();
      }
    },

    async getData(id) {
      this.$vs.loading();
      this.$http
        .get("api/wms/v1/setting/users/" + id)
        .then((resp) => {
          if (resp.code == 200) {
            this.selectedWorkGroup = {
              id: resp.data.work_group_id,
            };

            this.selectedRoles = {
              id: resp.data.roles_id,
              name: resp.data.role_name,
              display_name: resp.data.role_name,
            };
            console.log("LLLLLLLLLL", this.selectedRoles);
            // console.log("PPPPPPP", this.optionRoles);

            //convert idNumber to number for example LA4344G12 convert to 434412
            let convertIdNumber;
            if (resp.data.id_number != null) {
              convertIdNumber = resp.data.id_number.replace(/\D/g, "");
            }

            let convertLicenceNumber;
            if (resp.data.license_number != null) {
              convertLicenceNumber = resp.data.license_number.replace(
                /\D/g,
                ""
              );
            }

            let convertMobile;
            if (resp.data.mobile != null) {
              convertMobile = resp.data.mobile.replace(/\D/g, "");
            }

            this.user_id = resp.data.user_id;
            this.personal_id = resp.data.id;
            this.workIDNumber = resp.data.work_id_number;
            this.idNumber = convertIdNumber;
            this.name = resp.data.name;
            this.address = resp.data.address;
            this.postal_code = resp.data.postal_code;
            this.mobile = convertMobile;
            this.email = resp.data.email;
            this.password = resp.data.password;
            this.status = resp.data.status_active;
            this.selectedLincenseType = resp.data.license_type;
            this.licenseNumber = convertLicenceNumber;
            this.licenseValidity = resp.data.license_validity;
            this.selectedType =
              resp.data.type == "Internal"
                ? { id: 1, name: "Internal" }
                : resp.data.type == "External"
                ? { id: 2, name: "External" }
                : { id: 3, name: "OneTime" };
            // this.avatar = resp.data.avatar;
            // this.attachment = resp.data.attachment;
            // this.mappingAttachment();
            // this.fileAttachment = resp.data.attachment;
            // this.getRoles();

            this.isTransactions = resp.data.is_transaction ? true : false;

            this.selectedWarehouse = resp.data.warehouse;
            this.selectedLincenseType = {
              name: resp.data.license_type,
            };
            this.selectedLincenseType = this.optionLincenseType.find(
              (el) => el.name == this.selectedLincenseType.name
            );

            this.selectedCountry = {
              name: resp.data.country,
            };
            this.selectedProvince = {
              name: resp.data.province,
            };

            this.selectedCity = {
              name: resp.data.city,
            };
            this.selectedDistrict = {
              name: resp.data.district,
            };
            this.selectedSubDistrict = {
              name: resp.data.sub_district,
              postal_code: resp.data.postal_code,
            };
            if (this.selectedRoles.name.toLowerCase() == "driver") {
              this.isDisabled = false;
            } else {
              this.isDisabled = true;
            }

            if (resp.data.avatar != null) {
              this.avatar = resp.data.avatar;
            }
            if (resp.data.attachment != []) {
              resp.data.attachment.forEach((el) => {
                this.fileAttachment.push({
                  id: el.id,
                  NameFile: el.name,
                  PathFile: el.url,
                });
              });
            }

            // if (resp.data.warehouse.length > 0) {
            //   this.selectedWarehouse = {
            //     id: resp.data.warehouse[0].id,
            //     name: resp.data.warehouse[0].name,
            //   };
            // }
            this.getWorkGroup();
            this.getCountry();
            this.getWarehouse();
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
